@import "../../_globalColor";

.pdf-card {
  position: relative;
  box-shadow: $lightBoxShadowDark 0px 10px 30px;
  border-radius: 10px;
}

.pdf-card:hover {
  box-shadow: $lightBoxShadowDark 0px 15px 35px;
}

.pdf-pageNumber {
    position: relative;
    margin: 10px;

}

.pdf-button {
    background-color: $lightBackground2;
    border-radius: 50%;
    border: 1px solid $lightBorder1;
    scale: 1.05;
}
.pdf-button-dark {
    background-color: $darkBackground;
    color: white;
    border-radius: 50%;
    border: 1px solid $lightBorder1;
}
