.pdf-heading {
    font-size: 56px;
    font-weight: 400;
  }
  
  .pdf-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    height:max-content;
    padding: 20px 0px;
    margin: 0px auto;
    margin-top: 2rem;
  }
  
  .pdf-section > * {
    flex: 1;
    margin-bottom: 30px;
  }
  
  .pdf-card-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 1rem 1rem;

  }
  
  @media (max-width: 1380px) {
    .pdf-heading {
      font-size: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .pdf-heading {
      font-size: 30px;
      text-align: center;
    }
  }
  