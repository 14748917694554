.gallery-heading {
    font-size: 56px;
    font-weight: 400;
  }
  
  .gallery-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 20px 0px;
    margin: 0px auto;
    margin-top: 2rem;
  }
  
  .gallery-section > * {
    flex: 1;
    margin-bottom: 30px;
  }
  
  .gallery-card-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
  
  @media (max-width: 1380px) {
    .gallery-card-container {
      padding-top: 0px;
    }
    .gallery-heading {
      font-size: 40px;
    }
    .gallery-text-subtitle {
      font-size: 18px;
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .gallery-card-container {
      padding-top: 0px;
    }
    .gallery-heading {
      font-size: 30px;
      text-align: center;
    }
    .gallery-text-subtitle {
      font-size: 16px;
    }
  }
  